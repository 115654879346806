.promo-code {
  position: absolute;
  inset: 0;
  z-index: 1;
  background-color: #fff;
  color: #3B7CED;
  align-items: center;
  font-size: 13px;
  font-weight: 700;

  span {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    padding: 0 0 0 2px;
    height: 100%;
  }
}
