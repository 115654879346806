:root {
  // colors
  --c-bg-page: #2F0054;
  --c-svg-page: #3F0071;
  --c-sunrise: #FEC847;
  --c-neon-carrot: #FFA63F;
  --c-canary-bright-yellow: #FCF793;
  --c-bright-pink: #FE00BD;
  --c-white: #FFFFFF;
  --c-dark-orchid: #A815ED;
  --c-mauvein-aniline-purple: #EE008F;

  // offsets
  --v-offset-lg: min(160px, 8vmax);
  --v-offset-md: min(100px, 5vmax);
  --v-offset-sm: min(50px, 3vmax);

  @media (width >= 992px) {
    --v-offset-md: min(80px, 4vmax);
    --v-offset-sm: min(40px, 2vmax);
  }

  // fonts
  --min-fs: .6;
  --max-fs: 1;
  --min-vw: 100;
  --max-vw: 150;
  --font-family-default: "Montserrat";
  --default-line-height: 1.5;
  --heading-line-height: 1.3em;

  // scrollbar
  --c-scrollbar-track: var(--c-svg-page);
  --c-scrollbar-thumb: var(--c-dark-orchid);
  --c-scrollbar-hover: var(--c-dark-orchid);
}
