.text-field-container {
	& ::-webkit-input-placeholder {
		color: var(--tf-c-placeholder);
		opacity: 0;
		transition: opacity 225ms var(--tf-transition-easing);
	}

	& :focus::-webkit-input-placeholder {
		opacity: .65;
	}

	& :-ms-input-placeholder {
		color: var(--tf-c-placeholder);
		opacity: 0;
		transition: opacity 225ms var(--tf-transition-easing);
	}

	& :focus:-ms-input-placeholder {
		opacity: .65;
	}

	& ::-moz-placeholder {
		color: var(--tf-c-placeholder);
		opacity: 0;
		transition: opacity 225ms var(--tf-transition-easing);
	}

	& :focus::-moz-placeholder {
		opacity: .65;
	}

	& :-moz-placeholder {
		color: var(--tf-c-placeholder);
		opacity: 0;
		transition: opacity 225ms var(--tf-transition-easing);
	}

	& :focus:-moz-placeholder {
		opacity: .65;
	}
}
