.btn-auth {
  display: grid;
  place-content: center;
  height: 42px;
  margin-top: 16px;
  color: White;
  font-weight: 700;
  font-size: 15px;
  border-radius: 42px;
  background: linear-gradient(0.25turn, #EB0291, #AC09E7);
  cursor: pointer;
}
