.tickbox {
	input[type='radio']:not([role=switch]) {
		&::before,
		&::after {
			--tickbox-radio: var(--tickbox-color-inactive);

			transition: border-color 90ms ease, background-color 90ms ease, transform 90ms ease;
		}

		&::before {
			background-color: var(--tickbox-radio);
			border-radius: var(--tickbox-radio-mark-radius, 10px);
			width: 10px; height: 10px;
			transform: scale(0);
		}

		&::after {
			border-width: var(--tickbox-border-width, 2px);
			border-style: solid;
			border-color: var(--tickbox-radio);
			border-radius: var(--tickbox-radio-border-radius, 20px);
			width: 20px; height: 20px;
		}

		&:checked {
			&::before,
			&::after {
				--tickbox-radio: var(--tickbox-color-active);
			}

			&::before {
				transform: scale(1);
			}
		}

		&:disabled {
			&::before,
			&::after {
				--tickbox-radio: var(--tickbox-color-disabled);
			}
		}
	}
}