html,
body {
  overflow-x: hidden;
  position: relative;
  width: 100vw;
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #121212;
}
