.floating-label {
	display: inline-block;
	max-width: calc(100% - 30px);
	right: initial; left: 17px;
	pointer-events: none;
	font-family: var(--tf-font-family);
	font-size: var(--tf-font-size-field);
	font-weight: var(--tf-font-weight);
	letter-spacing: var(--tf-letter-spacing);
	line-height: 23px;
	color: var(--tf-c-text-field);
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	position: absolute;
	transform-origin: left top;
	transition: transform 150ms var(--tf-transition-easing),
	color 150ms var(--tf-transition-easing);
	text-align: left;
	text-overflow: ellipsis;
	white-space: nowrap;
	cursor: text;
	overflow: hidden;

	.input & {
		top: 50%;
		transform: translateY(-50%);
	}

	.textarea & {
		top: 13px;
	}

	.input--focused &,
	.input--filled & {
		transform: translateY(-33px) scale(.75);
	}

	.input--focused.input--leading-icon &,
	.input--filled.input--leading-icon & {
		transform: translateY(-33px) translateX(-27px) scale(.75);
	}

	.input--focused.input--dense &,
	.input--filled.input--dense & {
		transform: translateY(-27px) scale(.75);
	}

	.input--focused.input--large &,
	.input--filled.input--large & {
		transform: translateY(-44px) scale(.75);
	}

	.input--focused.input--dense.input--leading-icon &,
	.input--filled.input--dense.input--leading-icon & {
		transform: translateY(-27px) translateX(-27px) scale(.75);
	}

	.input--focused.input--large.input--leading-icon &,
	.input--filled.input--large.input--leading-icon & {
		transform: translateY(-44px) translateX(-27px) scale(.75);
	}

	.textarea--focused &,
	.textarea--filled & {
		transform: translateY(-22px) scale(.75);
	}
}
