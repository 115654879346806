.tickbox {
	input[type='checkbox']:not([role=switch]) {
		&::before,
		&::after {
			--tickbox-checkbox: var(--tickbox-color-inactive);

			border-radius: var(--tickbox-checkbox-radius, 2px);
			border-color: var(--tickbox-checkbox);
			transition: border-color 90ms ease, background-color 90ms ease, transform 225ms ease;
		}

		&::before {
			border-width: var(--tickbox-border-width, 2px);
			border-style: solid;
			width: 20px; height: 20px;
		}

		&::after {
			background-repeat: no-repeat;
			background-size: 18px 18px;
			background-position: center center;
			background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgZmlsbD0iI2ZmZiI+PHBhdGggZD0ibTcgMTMuNC00LTRMNC40IDggNyAxMC42IDEzLjYgNCAxNSA1LjRsLTggOFoiLz48L3N2Zz4=);
			width: 20px; height: 20px;
			opacity: 0;
			transform: rotate(-25deg);
		}

		&:checked {
			&::before {
				--tickbox-checkbox: var(--tickbox-color-active);

				background-color: var(--tickbox-checkbox);
			}

			&::after {
				opacity: 1;
				transform: rotate(0);
			}
		}

		&:disabled {
			&::before,
			&::after {
				--tickbox-checkbox: var(--tickbox-color-disabled);
			}
		}
	}
}