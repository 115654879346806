@import "promo-code";
@import "currency";
@import "auth-rules";
@import "text-fields";
@import "buttons";

.auth {
  min-height: 450px;
  padding: 30px 25px;
  background-color: #fff;
  width: calc(100% - 40px);

  @media (width >= 576px) {
    width: 400px;
  }

  &--sign-in {
    --display-sign-in: none;
    --display-sign-up: grid;
  }

  &--sign-up {
    --display-sign-in: grid;
    --display-sign-up: none;
  }

  &-default {
    display: grid;
    grid-template: auto / auto auto;
    gap: 0 20px;
    margin-bottom: 28px;
    font-weight: 400;
    font-size: 14px;

    & :last-child {
      justify-self: start;
    }

    & :first-child {
      justify-self: end;
    }

    & [class*="sign"].active {
      cursor: default;
      color: Gray;
    }

    & [class*="sign"]:not(.active) {
      box-shadow: 0 1px 0 0 #3B7CED;
      cursor: pointer;

      &:hover {
        color: #3B7CED;
        box-shadow: none;
      }
    }
  }

  &-form {
    display: grid;
    gap: 20px 0;

    &__item {
      position: relative;
    }

    &--sign-in {
      display: var(--display-sign-in, none);
      grid-template: auto auto auto auto auto / auto;
    }

    &--sign-up {
      display: var(--display-sign-up, none);
      grid-template: auto auto auto / auto;
    }
  }
}
